var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "후원하기",
      "tabActive": "물품후원",
      "bg": "/images/sub/visual/sv3.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('section', {
    staticClass: "page-section"
  }, [_c('div', {
    staticClass: "mb-20 mb-md-80 mb-xl-134 text-center"
  }, [_c('h3', {
    staticClass: "tit tit--md line-height-1 mb-8 mb-lg-16"
  }, [_vm._v(" 물품 후원 ")]), _c('p', {
    staticClass: "page-text page-text--lg line-height-15"
  }, [_vm._v(" 가장 필요한 곳에 물품을 나누고, 기업의 가치를 더하는 사랑밭 물품 후원 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 도움이 필요한 국내외 소외계층에게 희망을 전달할 수 있는 나눔 실천, 물품후원을 받고 있습니다. "), _c('br'), _vm._v(" 예상치를 벗어난 기업 이월/재고상품과 개인물품 기부로 어려운 이웃을 도울 수 있습니다. ")])]), _c('div', {
    staticClass: "image-full-card py-40 py-lg-80"
  }, [_c('div', {
    staticClass: "image-full-card__inner mb-n20 mb-md-0"
  }, [_c('h3', {
    staticClass: "tit tit--md secondary--text line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" \"정직하게 전할 것을 약속합니다.\" ")]), _c('ul', [_c('li', {
    staticClass: "d-flex align-center mb-12 mb-lg-24"
  }, [_c('h4', {
    staticClass: "tit tit--sm primary--text line-height-1 w-auto min-w-60px mr-lg-10"
  }, [_vm._v(" 하나. ")]), _c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 기업 브랜드가치를 보호하는데 중점을 두겠습니다! ")])]), _c('li', {
    staticClass: "d-flex align-center mb-12 mb-lg-24"
  }, [_c('h4', {
    staticClass: "tit tit--sm primary--text line-height-1 w-auto min-w-60px mr-lg-10"
  }, [_vm._v(" 둘. ")]), _c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 지정하신 취약계층에게 정확히 전달하여 행복을 나누겠습니다. ")])]), _c('li', {
    staticClass: "d-flex align-center"
  }, [_c('h4', {
    staticClass: "tit tit--sm primary--text line-height-1 w-auto min-w-60px mr-lg-10"
  }, [_vm._v(" 셋. ")]), _c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(" 나눔의 시작이 더 큰 사랑으로 이어지도록 노력하겠습니다. ")])])])]), _c('v-img', {
    staticClass: "image-full-card__image",
    attrs: {
      "src": "/images/sub/support/commodity/s1-img.png",
      "max-width": "704"
    }
  })], 1)]), _c('section', {
    staticClass: "page-section"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" 물품후원, 이렇게 진행됩니다! ")]), _c('v-row', {
    staticClass: "flex-md-nowrap row-cols-md-5 row-cols-lg-auto process-group word-keep-all",
    attrs: {
      "justify": "center",
      "justify-lg": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "process text-center"
  }, [_c('div', {
    staticClass: "process-icon-wrap mx-auto mb-16 mb-lg-28"
  }, [_c('div', {
    staticClass: "process-icon"
  }, [_c('div', {
    staticClass: "process-icon__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/commodity/process-icon1.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 접수 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 후원물품 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 문의 및 접수 ")])])]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "process text-center"
  }, [_c('div', {
    staticClass: "process-icon-wrap mx-auto mb-16 mb-lg-28"
  }, [_c('div', {
    staticClass: "process-icon"
  }, [_c('div', {
    staticClass: "process-icon__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/commodity/process-icon2.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 소통 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 후원 담당자와 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 기증방법 소통 ")])])]), _c('v-col', {
    staticClass: "mt-10 mr-sm-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "process text-center"
  }, [_c('div', {
    staticClass: "process-icon-wrap mx-auto mb-16 mb-lg-28"
  }, [_c('div', {
    staticClass: "process-icon"
  }, [_c('div', {
    staticClass: "process-icon__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/commodity/process-icon3.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 기증/수령 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 물품운송 및 수령 ")])])]), _c('v-col', {
    staticClass: "mt-10 mr-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "process text-center"
  }, [_c('div', {
    staticClass: "process-icon-wrap mx-auto mb-16 mb-lg-28"
  }, [_c('div', {
    staticClass: "process-icon"
  }, [_c('div', {
    staticClass: "process-icon__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/commodity/process-icon4.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 배분/전달 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 물품분배 및 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 취약계층 전달 ")])])]), _c('v-col', {
    staticClass: "mt-10 mr-md-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "process text-center"
  }, [_c('div', {
    staticClass: "process-icon-wrap mx-auto mb-16 mb-lg-28"
  }, [_c('div', {
    staticClass: "process-icon"
  }, [_c('div', {
    staticClass: "process-icon__inner"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/support/commodity/process-icon5.svg",
      "contain": "",
      "max-height": "50"
    }
  })], 1)])]), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 홍보/피드백 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 보도자료 배포 및 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 결과 피드백 ")])])])], 1)], 1), _c('section', {
    staticClass: "page-section"
  }, [_c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-8 mb-lg-16"
  }, [_vm._v(" 물품후원, 이렇게 진행됩니다! ")]), _c('p', {
    staticClass: "page-text page-text--lg grey-1--text line-height-15"
  }, [_vm._v(" 물품후원은 단순한 기부가 아닌 국내외 도움이 필요한 이웃들에게 가장 큰 기쁨이 됩니다. 후원을 해주시면 다양한 효과 제공 및 혜택을 받으실 수 있습니다. ")])]), _c('v-row', {
    staticClass: "border-top border-bottom text-center word-keep-all",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "border-bottom border-sm-bottom-0 border-sm-end",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 pa-20 pa-md-30 py-lg-60 d-flex flex-column align-center justify-center",
    staticStyle: {
      "background-color": "#ECF7E9"
    }
  }, [_c('v-img', {
    staticClass: "mb-16 mb-lg-24",
    attrs: {
      "src": "/images/sub/support/commodity/s3-icon1.svg",
      "contain": "",
      "max-height": "60"
    }
  }), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 기업가치 상승 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 사회적 책임 실천으로 기업 이미지 재고 ")])], 1)]), _c('v-col', {
    staticClass: "border-bottom border-sm-bottom-0 border-sm-end",
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 pa-20 pa-md-30 py-lg-60 d-flex flex-column align-center justify-center"
  }, [_c('v-img', {
    staticClass: "mb-16 mb-lg-24",
    attrs: {
      "src": "/images/sub/support/commodity/s3-icon2.svg",
      "contain": "",
      "max-height": "60"
    }
  }), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 비용 절감 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 물류보관, 소각, 부대이용비 절감 ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 pa-20 pa-md-30 py-lg-60 d-flex flex-column align-center justify-center",
    staticStyle: {
      "background-color": "#ECF7E9"
    }
  }, [_c('v-img', {
    staticClass: "mb-16 mb-lg-24",
    attrs: {
      "src": "/images/sub/support/commodity/s3-icon3.svg",
      "contain": "",
      "max-height": "60"
    }
  }), _c('h6', {
    staticClass: "page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8"
  }, [_vm._v(" 환경보호 ")]), _c('p', {
    staticClass: "page-text"
  }, [_vm._v(" 상품 재사용으로 환경보호에 기여 ")])], 1)])], 1), _c('div', {
    staticClass: "page-text"
  }, [_c('div', {
    staticClass: "py-20 px-8 py-md-24"
  }, [_c('p', {
    staticClass: "dot-text"
  }, [_vm._v(" 기관 홈페이지, SNS, 블로그 등 다양한 온라인 채널 및 협의된 보도자료 배포를 통해 홍보를 도와드립니다. ")])]), _c('v-divider'), _c('div', {
    staticClass: "py-20 px-8 py-md-24"
  }, [_c('p', {
    staticClass: "dot-text"
  }, [_vm._v(" 법인세법 24조, 소득세법 34조에 의거하여 기부물품은 장부가액을 기준으로 기부금영수증을 발행하여 드리며 소득공제/세금감면의 혜택을 받을 수 있습니다. ")])]), _c('v-divider'), _c('div', {
    staticClass: "py-20 px-8 py-md-24"
  }, [_c('p', {
    staticClass: "dot-text"
  }, [_vm._v(" 근로자는 2월 소득공제기간에, 자영업자/사업자는 5월 종합소득세 신고기간에 전년도 1월~12월 동안의 기부금영수증을 증빙으로 사용하시면 됩니다. "), _c('br'), _vm._v(" (* 후원자님의 개인정보는 기부금영수증 발행, 관리 의외의 어떠한 목적으로도 사용되지 않습니다.) ")])]), _c('v-divider'), _c('div', {
    staticClass: "py-20 px-8 py-md-24"
  }, [_c('p', {
    staticClass: "dot-text"
  }, [_vm._v(" 현물기부시 장부가액확인서가 필요합니다. "), _c('br'), _vm._v(" (장부가액 세부내역은 외부 비공개를 원칙으로 하고 있습니다) "), _c('br'), _vm._v(" * 자산의 최초 취득가액 ")])]), _c('v-divider'), _c('div', {
    staticClass: "py-20 px-8 py-md-24"
  }, [_c('p', {
    staticClass: "dot-text"
  }, [_vm._v(" 현물기부시 장부가액확인서가 필요합니다. "), _c('br'), _vm._v(" (장부가액 세부내역은 외부 비공개를 원칙으로 하고 있습니다) "), _c('br'), _vm._v(" * 자산의 최초 취득가액 ")])]), _c('v-divider')], 1)], 1), _c('section', {
    staticClass: "page-section"
  }, [_c('donation-product-form', {
    attrs: {
      "code": "물품후원"
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
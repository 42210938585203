<template>
    <client-page>

        <sub-visual
        sh="후원하기"
        tabActive="물품후원"
        bg="/images/sub/visual/sv3.jpg">
        </sub-visual>

        <section class="section">
            <v-container>

                <section class="page-section">

                    <div class="mb-20 mb-md-80 mb-xl-134 text-center">
                        <h3 class="tit tit--md line-height-1 mb-8 mb-lg-16">
                            물품 후원
                        </h3>
                        <p class="page-text page-text--lg line-height-15">
                            가장 필요한 곳에 물품을 나누고, 기업의 가치를 더하는 사랑밭 물품 후원 <br class="d-none d-md-block">
                            도움이 필요한 국내외 소외계층에게 희망을 전달할 수 있는 나눔 실천, 물품후원을 받고 있습니다. <br>
                            예상치를 벗어난 기업 이월/재고상품과 개인물품 기부로 어려운 이웃을 도울 수 있습니다.
                        </p>
                    </div>

                    <div class="image-full-card py-40 py-lg-80">
                        <div class="image-full-card__inner mb-n20 mb-md-0">
                            <h3 class="tit tit--md secondary--text line-height-1 mb-20 mb-lg-40">
                                "정직하게 전할 것을 약속합니다."
                            </h3>
                            <ul>
                                <li class="d-flex align-center mb-12 mb-lg-24">
                                    <h4 class="tit tit--sm primary--text line-height-1 w-auto min-w-60px mr-lg-10">
                                        하나.
                                    </h4>
                                    <p class="page-text page-text--lg">
                                        기업 브랜드가치를 보호하는데 중점을 두겠습니다!
                                    </p>
                                </li>
                                <li class="d-flex align-center mb-12 mb-lg-24">
                                    <h4 class="tit tit--sm primary--text line-height-1 w-auto min-w-60px mr-lg-10">
                                        둘.
                                    </h4>
                                    <p class="page-text page-text--lg">
                                        지정하신 취약계층에게 정확히 전달하여 행복을 나누겠습니다.
                                    </p>
                                </li>
                                <li class="d-flex align-center">
                                    <h4 class="tit tit--sm primary--text line-height-1 w-auto min-w-60px mr-lg-10">
                                        셋.
                                    </h4>
                                    <p class="page-text page-text--lg">
                                        나눔의 시작이 더 큰 사랑으로 이어지도록 노력하겠습니다.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <v-img src="/images/sub/support/commodity/s1-img.png" max-width="704" class="image-full-card__image"></v-img>
                    </div>
                </section>

                <section class="page-section">
                    <h4 class="tit tit--sm line-height-1 mb-20 mb-lg-40">
                        물품후원, 이렇게 진행됩니다!
                    </h4>

                    <v-row class="flex-md-nowrap row-cols-md-5 row-cols-lg-auto process-group word-keep-all" justify="center" justify-lg="space-between">
                        <v-col cols="6" sm="4" md="">
                            <div class="process text-center">
                                <div class="process-icon-wrap mx-auto mb-16 mb-lg-28">
                                    <div class="process-icon">
                                        <div class="process-icon__inner">
                                            <v-img src="/images/sub/support/commodity/process-icon1.svg" contain max-height="50"></v-img>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    접수
                                </h6>
                                <p class="page-text">
                                    후원물품 <br class="d-none d-md-block">
                                    문의 및 접수
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="4" md="">
                            <div class="process text-center">
                                <div class="process-icon-wrap mx-auto mb-16 mb-lg-28">
                                    <div class="process-icon">
                                        <div class="process-icon__inner">
                                            <v-img src="/images/sub/support/commodity/process-icon2.svg" contain max-height="50"></v-img>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    소통
                                </h6>
                                <p class="page-text">
                                    후원 담당자와 <br class="d-none d-md-block">
                                    기증방법 소통
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="mt-10 mr-sm-0">
                            <div class="process text-center">
                                <div class="process-icon-wrap mx-auto mb-16 mb-lg-28">
                                    <div class="process-icon">
                                        <div class="process-icon__inner">
                                            <v-img src="/images/sub/support/commodity/process-icon3.svg" contain max-height="50"></v-img>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    기증/수령
                                </h6>
                                <p class="page-text">
                                    물품운송 및 수령
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="mt-10 mr-md-0">
                            <div class="process text-center">
                                <div class="process-icon-wrap mx-auto mb-16 mb-lg-28">
                                    <div class="process-icon">
                                        <div class="process-icon__inner">
                                            <v-img src="/images/sub/support/commodity/process-icon4.svg" contain max-height="50"></v-img>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    배분/전달
                                </h6>
                                <p class="page-text">
                                    물품분배 및 <br class="d-none d-md-block">
                                    취약계층 전달
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="6" sm="4" md="" class="mt-10 mr-md-0">
                            <div class="process text-center">
                                <div class="process-icon-wrap mx-auto mb-16 mb-lg-28">
                                    <div class="process-icon">
                                        <div class="process-icon__inner">
                                            <v-img src="/images/sub/support/commodity/process-icon5.svg" contain max-height="50"></v-img>
                                        </div>
                                    </div>
                                </div>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    홍보/피드백
                                </h6>
                                <p class="page-text">
                                    보도자료 배포 및 <br class="d-none d-md-block">
                                    결과 피드백
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </section>

                <section class="page-section">
                    <div class="mb-20 mb-lg-40">
                        <h4 class="tit tit--sm line-height-1 mb-8 mb-lg-16">
                            물품후원, 이렇게 진행됩니다!
                        </h4>
                        <p class="page-text page-text--lg grey-1--text line-height-15">
                            물품후원은 단순한 기부가 아닌 국내외 도움이 필요한 이웃들에게 가장 큰 기쁨이 됩니다. 후원을 해주시면 다양한 효과 제공 및 혜택을 받으실 수 있습니다.
                        </p>
                    </div>
                    <v-row no-gutters class="border-top border-bottom text-center word-keep-all">
                        <v-col cols="12" sm="4" class="border-bottom border-sm-bottom-0 border-sm-end">
                            <div class="w-100 h-100 pa-20 pa-md-30 py-lg-60 d-flex flex-column align-center justify-center" style="background-color: #ECF7E9">
                                <v-img src="/images/sub/support/commodity/s3-icon1.svg" contain max-height="60" class="mb-16 mb-lg-24"></v-img>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    기업가치 상승
                                </h6>
                                <p class="page-text">
                                    사회적 책임 실천으로 기업 이미지 재고
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4" class="border-bottom border-sm-bottom-0 border-sm-end">
                            <div class="w-100 h-100 pa-20 pa-md-30 py-lg-60 d-flex flex-column align-center justify-center">
                                <v-img src="/images/sub/support/commodity/s3-icon2.svg" contain max-height="60" class="mb-16 mb-lg-24"></v-img>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    비용 절감
                                </h6>
                                <p class="page-text">
                                    물류보관, 소각, 부대이용비 절감
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <div class="w-100 h-100 pa-20 pa-md-30 py-lg-60 d-flex flex-column align-center justify-center" style="background-color: #ECF7E9">
                                <v-img src="/images/sub/support/commodity/s3-icon3.svg" contain max-height="60" class="mb-16 mb-lg-24"></v-img>
                                <h6 class="page-text page-text--lg grey-1--text line-height-1 mb-4 mb-lg-8">
                                    환경보호
                                </h6>
                                <p class="page-text">
                                    상품 재사용으로 환경보호에 기여
                                </p>
                            </div>
                        </v-col>
                    </v-row>

                    <div class="page-text">
                        <div class="py-20 px-8 py-md-24">
                            <p class="dot-text">
                                기관 홈페이지, SNS, 블로그 등 다양한 온라인 채널 및 협의된 보도자료 배포를 통해 홍보를 도와드립니다.
                            </p>
                        </div>
                        <v-divider />
                        <div class="py-20 px-8 py-md-24">
                            <p class="dot-text">
                                법인세법 24조, 소득세법 34조에 의거하여 기부물품은 장부가액을 기준으로 기부금영수증을 발행하여 드리며 소득공제/세금감면의 혜택을 받을 수 있습니다.
                            </p>
                        </div>
                        <v-divider />
                        <div class="py-20 px-8 py-md-24">
                            <p class="dot-text">
                                근로자는 2월 소득공제기간에, 자영업자/사업자는 5월 종합소득세 신고기간에 전년도 1월~12월 동안의 기부금영수증을 증빙으로 사용하시면 됩니다. <br>
                                (* 후원자님의 개인정보는 기부금영수증 발행, 관리 의외의 어떠한 목적으로도 사용되지 않습니다.)
                            </p>
                        </div>
                        <v-divider />
                        <div class="py-20 px-8 py-md-24">
                            <p class="dot-text">
                                현물기부시 장부가액확인서가 필요합니다. <br>
                                (장부가액 세부내역은 외부 비공개를 원칙으로 하고 있습니다) <br>
                                * 자산의 최초 취득가액
                            </p>
                        </div>
                        <v-divider />
                        <div class="py-20 px-8 py-md-24">
                            <p class="dot-text">
                                현물기부시 장부가액확인서가 필요합니다. <br>
                                (장부가액 세부내역은 외부 비공개를 원칙으로 하고 있습니다) <br>
                                * 자산의 최초 취득가액
                            </p>
                        </div>
                        <v-divider />
                    </div>

                </section>

                <section class="page-section">
                    <donation-product-form code="물품후원"/>
                </section>
            </v-container>
        </section>
        

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import DonationProductForm from "@/components/client/support/donation-product-form.vue"

export default {
    components: {
        ClientPage,
        SubVisual,
        DonationProductForm
    },
    data() {
        return {
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        async init(){
        }
    },
    watch:{
        code(){
        }
    }
}
</script>

<style lang="scss" scoped>
.image-full-card{
    position: relative;
    width: 100%;
}
.image-full-card::before{
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background-color: #f5f5f5;
}
.image-full-card__image{
    position: relative;
    bottom: -40px;
    width: 60%;
    margin-left: auto;
}
.image-full-card__inner{
    position: relative;
    z-index: 2;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .image-full-card__image{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-left: 0;
        width: 50%;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .image-full-card__image{
        width: 60%;
    }
}

.process-icon-wrap{
    width: 100%;
    max-width: 140px;
}
.process-icon{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 50%;
    background-color: var(--v-primary-base);
}
.process-icon__inner{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .process-icon-wrap{
        min-width: 160px;
    }
}
@media (min-width:1200px){
    .process-group{
        position: relative;
    }
    .process-group::before{
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + 24px);
        height: 24px;
        left: 50%;
        top: calc(50% - 49px);
        transform: translate(-50%, -50%);
        background-image: url(/images/sub/support/commodity/process-arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: calc(100% - 24px) auto;

    }
}

</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-20 mb-lg-40"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(" " + _vm._s(_vm.code) + " ")])]), _c('p', {
    staticClass: "form__caption"
  }, [_vm._v("* 필수입력 항목입니다.")]), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "신청구분"
    }
  }, [_c('p', {
    staticClass: "font-size-14 grey-6--text"
  }, [_vm._v(_vm._s(_vm.code))])]), _c('form-row', {
    attrs: {
      "tit": "담당자명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "담당자명을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.manager,
      callback: function ($$v) {
        _vm.$set(_vm.product, "manager", $$v);
      },
      expression: "product.manager"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "회사명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "회사명을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.product, "companyName", $$v);
      },
      expression: "product.companyName"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "부서명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "부서명을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.departmentName,
      callback: function ($$v) {
        _vm.$set(_vm.product, "departmentName", $$v);
      },
      expression: "product.departmentName"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "연락처",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "lg": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-lg-200px",
    attrs: {
      "items": _vm.prependPhones,
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.product, "phone1", $$v);
      },
      expression: "product.phone1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8",
      "lg": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "‘-’를 제외하고 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.product, "phone2", $$v);
      },
      expression: "product.phone2"
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "이메일",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "이메일을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email1,
      callback: function ($$v) {
        _vm.email1 = $$v;
      },
      expression: "email1"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("@")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function () {
        return _vm.emailJoin();
      }
    },
    model: {
      value: _vm.email2,
      callback: function ($$v) {
        _vm.email2 = $$v;
      },
      expression: "email2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "persistent-hint": "",
      "items": _vm.prependEmails,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.email2 = _vm.email3;
      }
    },
    model: {
      value: _vm.email3,
      callback: function ($$v) {
        _vm.email3 = $$v;
      },
      expression: "email3"
    }
  }, 'v-select', _vm.$attrs, false))], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "문의내용",
      "pointer": ""
    }
  }, [_c('v-textarea', {
    attrs: {
      "rows": "9",
      "placeholder": "내용을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.inquiry,
      callback: function ($$v) {
        _vm.$set(_vm.product, "inquiry", $$v);
      },
      expression: "product.inquiry"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form__agreement"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "개인정보 수집·이용 동의",
      "value": "개인정보 수집·이용 동의",
      "hide-details": ""
    },
    model: {
      value: _vm.agree1,
      callback: function ($$v) {
        _vm.agree1 = $$v;
      },
      expression: "agree1"
    }
  }), _c('button', {
    staticClass: "form__agreement__btn",
    attrs: {
      "onclick": "window.open('/privacy-policy', '', 'width=483,height=700');"
    }
  }, [_vm._v("전문보기")])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("신청하기")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }